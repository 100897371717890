<template>
	<div>
		<Head />
		<div class="body">
			<el-image src="https://oss.djsmart.vip/app/home06.jpg" fit="scale-down" />
			<div class="hero">
				<div class="title1">AI图像大师</div>
				<div class="title2">一款高效便捷的智能图片处理工具</div>
				<button class="main-btn" @click="experienceNow('/Dashboard')">开始免费体验</button>
			</div>
			<div class="line-box">
				<div class="line-box-left">
					<div id="bottomImg" class="bottomImg" :style="{ height: imgHeigth, width: imgWidth, backgroundImage: 'url(' + bottomImg + ')' }">
						<div v-if="upperImg" class="upperImg" :style="{ backgroundImage: 'url(' + upperImg + ')', width: 100 - upperImgWidth + '%' }"></div>
						<div v-else class="upperUndefined" :style="{ width: 100 - upperImgWidth + '%' }">
							<span class="undefinedSpan">暂无结果</span>
						</div>
						<!-- 这是对外展示的滑块样式，仅仅是展示样式的，不然原生的样式不好看 -->
						<span class="spanHandle" :style="{ left: 'calc(' + upperImgWidth + '% - 24px)' }"></span>
						<!--滑块控制上层图片的宽度 -->
						<input class="inputRange" type="range" v-model="upperImgWidth" min="0" max="100" />
					</div>
				</div>
				<div class="line-box-right">
					<div class="desc-title">智能抠图</div>
					<div class="desc-content">全品类智能识别技术，实现一键自动精准抠图，轻松提取图像中的主体内容，为用户带来高效便捷的图像处理体验。</div>
					<el-button type="primary" style="margin-top: 20px; width: 190px; height: 50px" @click="experienceNow('/Dashboard/AIMatting')">免费体验</el-button>
				</div>
			</div>
			<div class="replace-bg-box">
				<div class="box-left">
					<div class="desc-title">背景一键替换</div>
					<div class="desc-content">
						只需轻轻一点，即可在瞬间完成背景的智能替换，不仅节省了大量的时间和精力，更让您的商品展示瞬间焕然一新，大幅提升工作效率，让您的商品展示始终保持前沿风采。
					</div>
					<el-button type="primary" style="margin-top: 20px; width: 190px; height: 50px" @click="experienceNow('/Dashboard/GoodsReplace')">免费体验</el-button>
					<el-image class="goods-src-img" fit="cover" src="https://oss.djsmart.vip/app/s001.jpg"></el-image>
				</div>
				
				<div class="bg-box-right">
					<div class="carousel-inner" :style="innerStyle">
						<div class="carousel-item" v-for="(item, index) in items" :key="index">
							<img style="border-radius: 10px;width: 720px;height: 540px;" :src="item.src" :alt="item.alt" />
						</div>
					</div>
					<div class="carousel-thumbnails">
						<div class="thumbnail-item" v-for="(item, index) in items" :key="index" :class="{ active: currentIndex === index }" @click="selectBgItem(index)">
							<el-image fit="cover" style="width: 100px; height: 100px; border-radius: 5px" :src="item.src" :alt="item.alt" class="thumbnail-image" />
						</div>
					</div>
				</div>
			</div>

			<div class="inspiration-box">
				<div class="inspiration">
					<h2 class="inspiration-title">涂鸦作画</h2>
					<div class="inspiration-desc">每一个细微的想法都能迅速转化为实实在在的创意，让思维的种子在行动的土壤中即时生根发芽，展现出其质朴而动人的魅力。</div>
					<div class="inspiration-content">
						<div class="inspiration-content-div">
							<el-carousel height="500px" :interval="3000">
								<el-carousel-item class="inspiration-content-item" v-for="item in inspirations1" :key="item">
									<el-image style="border-radius: 10px;" fit="scale-down" :src="item" />
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="inspiration-content-div">
							<el-carousel height="500px" :interval="2500">
								<el-carousel-item class="inspiration-content-item" v-for="item in inspirations2" :key="item">
									<el-image style="border-radius: 10px;" fit="scale-down" :src="item" />
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="inspiration-content-div">
							<el-carousel height="500px" :interval="3500">
								<el-carousel-item class="inspiration-content-item" v-for="item in inspirations3" :key="item">
									<el-image style="border-radius: 10px;" fit="scale-down" :src="item" />
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</div>
				<button class="main-btn" style="margin-top: 20px;" @click="experienceNow('/Dashboard/Draw')">开始免费体验</button>
			</div>
		</div>
		<Footer />
		<LoginDialogMain :dialogVisible.sync="dialogOpen" />
	</div>
</template>

<script>
import Cookies from 'js-cookie';
import LoginDialogMain from '@/components/LoginDialog';
import Head from '@/components/Head';
import Footer from '@/components/Footer';
import { loginUserInfo } from '@/utils/api';
export default {
	components: {
		LoginDialogMain,
		Head,
		Footer
	},
	mounted() {
		this.loadUserInfo();
		this.getImgSize();
		this.startAutoPlay();
		var opt = this.$route.query.opt;
		if(opt === 'login'){
			this.dialogOpen = true;
		}
		if(opt == 'console'){
			window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/#/Dashboard?token=' + Cookies.get('token');
		}
	},
	beforeDestroy() {
		this.stopAutoPlay(); // 组件销毁前清除定时器
	},
	computed: {
		innerStyle() {
			return {
				transform: `translateX(-${this.currentIndex * 100}%)`
			};
		}
	},
	data() {
		return {
			dialogOpen: false,
			loginFlag: false,
			inspirations1: ['https://oss.djsmart.vip/app/1008/a1.jpg', 'https://oss.djsmart.vip/app/1008/a2.jpg'],
			inspirations2: ['https://oss.djsmart.vip/app/1008/b1.jpg', 'https://oss.djsmart.vip/app/1008/b2.jpg'],
			inspirations3: ['https://oss.djsmart.vip/app/1008/c1.jpg', 'https://oss.djsmart.vip/app/1008/c2.jpg'],
			imgHeigth: '0px', // 图片高度
			imgWidth: '0px', // 图片宽度
			bottomImg: 'https://oss.djsmart.vip/app/mf.png', // 底图
			upperImg: 'https://oss.djsmart.vip/app/ma.jpg', // 上层图
			upperImgWidth: 50, // 上层图宽度
			bottomLabel: '', // 底图标签
			upperLabel: '',
			currentIndex: 0, // 当前显示的图片索引
			items: [
				// 走马灯项目数组
				{ src: 'https://oss.djsmart.vip/20241214/20241214205652265692430.png', alt: 'AI图像大师' },
				{ src: 'https://oss.djsmart.vip/20241214/20241214205902671182723.png', alt: 'AI图像大师' },
				{ src: 'https://oss.djsmart.vip/20241214/20241214205944728403870.png', alt: 'AI图像大师' },
				{ src: 'https://oss.djsmart.vip/20241214/20241214210021000373981.png', alt: 'AI图像大师' },
				{ src: 'https://oss.djsmart.vip/20241214/20241214210107644336531.png', alt: 'AI图像大师' }
				// ...更多图片
			],
			intervalId: null // 定时器ID
		};
	},
	methods: {
		selectBgItem(index){
			this.currentIndex = index;
			if(this.intervalId){
				this.intervalId = null;
			}
		},
		startAutoPlay() {
			this.intervalId = setInterval(() => {
				this.next();
			}, 3000);
		},
		stopAutoPlay() {
			clearInterval(this.intervalId);
		},
		next() {
			this.currentIndex = (this.currentIndex + 1) % this.items.length;
		},
		getImgSize() {
			var image = new Image();
			image.onload = () => {
				this.imgHeigth = image.height + 'px';
				this.imgWidth = image.width + 'px';
			};
			image.src = this.bottomImg;
		},
		experienceNow(url) {
			if (this.loginFlag) {
				this.$router.push({ path: url });
			} else {
				this.dialogOpen = true;
			}
		},
		loadUserInfo() {
			var token = Cookies.get('token');
			if (!token || !token.trim()) {
				return;
			}
			loginUserInfo().then((res) => {
				this.loginFlag = res.data !== null;
			});
		}
	}
};
</script>

<style lang="scss">
@use '@/views/index/Index.scss';
</style>
