<template>
	<div>
		<Head />
		<div class="body">
			<el-image src="https://oss.djsmart.vip/app/about02.jpg" fit="scale-down" />
			<div class="hero">
				<div class="title1">AI图像大师</div>
				<div class="title2">一款高效便捷的智能图片处理工具</div>
				<button class="main-btn" @click="experienceNow('/Dashboard')">开始免费体验</button>
			</div>
			<div class="desc container">
				<el-image src="https://oss.djsmart.vip/app/about04.jpg" fit="scale-down" />
				<div class="desc-txt">
					<div class="desc-title">关于我们</div>
					<div class="desc-line"></div>
					<div class="desc-content">
						东莞市洞见智能科技有限公司，自2024年崭露头角，坐落于风光秀丽的粤港澳大湾区核心地带——东莞。
						公司汇聚了一批来自知名互联网企业的精英，他们拥有超过10年的行业经验，对项目开发及运营管理有着深刻的理解和丰富的实战经验。
						<br/><br/>洞见智能科技致力于AI人工智能领域的创新与发展，旗下产品经过匠心独运的设计，赢得了广大用户和客户的青睐。
						<br/><br/>我们的团队既稳重务实，又充满激情，对于客户和用户的需求具有敏锐的洞察力和高效的响应能力。
						我们秉持着成为业界领先的人工智能服务商的发展宗旨，砥砺前行，不断开创辉煌。
					</div>
				</div>
			</div>
		</div>
		<Footer />
		<LoginDialogAbout :dialogVisible.sync="dialogOpen" />
	</div>
</template>

<script>
import Cookies from 'js-cookie';
import LoginDialogAbout from '@/components/LoginDialog';
import Head from '@/components/Head';
import Footer from '@/components/Footer';
import { loginUserInfo } from '@/utils/api';
export default {
	components: {
		LoginDialogAbout,
		Head,
		Footer
	},
	mounted() {
		this.loadUserInfo();
	},
	data() {
		return {
			dialogOpen: false,
			loginFlag: false //是否已登录
		};
	},
	methods: {
		experienceNow(url) {
			if (this.loginFlag) {
				this.$router.push({ path: url });
			} else {
				this.dialogOpen = true;
			}
		},
		loadUserInfo() {
			console.log('Cookies=============' + Cookies);
			var token = Cookies.get('token');
			if (!token || !token.trim()) {
				return;
			}
			loginUserInfo().then((res) => {
				this.loginFlag = res.data !== null;
			});
		},
		openLoginDialog() {
			this.dialogOpen = true;
		}
	}
};
</script>

<style lang="scss">
@use '@/views/about/About.scss';
</style>
