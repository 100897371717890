<template>
	<el-dialog :visible.sync="dialogVisible" width="400px" class="login-dialog" :before-close="handleClose">
		<div v-if="wxLogin">
			<div class="login-title">微信扫码登录</div>
			<div style="margin-top: 20px">
				<span>微信扫码关注公众号完成登录</span>
			</div>
			<div id="qrcode-wx" class="qrcode-wx">
				<img src="https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQEu8DwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyRlNCeDh4MjZib1QxTmhFa05DYy0AAgRBGtRmAwQQDgAA" />
			</div>
			<div class="separator-with-text"></div>
			<div class="otherLogin">
				<img src="@/assets/images/phone.png" />
				<span style="margin-left: 5px; cursor: pointer" @click="toSmsLogin()">短信登录</span>
			</div>
		</div>
		<div v-if="!wxLogin">
			<div class="login-title">短信验证码登录</div>
			<div id="login-sms" class="login-sms">
				<el-input placeholder="请输入手机号" v-model="phone" clearable></el-input>
				<div class="sms-code">
					<el-input placeholder="请输入验证码" v-model="smsCode" clearable></el-input>
					<el-button type="primary" plain @click="useVerify" :disabled="disableCodeSend">{{ resendBtnText }}</el-button>
				</div>
				<el-button class="login-btn" type="primary" @click="sendSmsLogin" :disabled="disabledLogin">登录</el-button>
			</div>
			<!-- <div class="separator-with-text"></div>
			<div class="otherLogin">
				<img src="@/assets/images/wx.png" />
				<span style="margin-left: 5px; cursor: pointer" @click="toWxLogin()">微信扫码登录</span>
			</div> -->
		</div>
		<Verify @success="success" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
	</el-dialog>
</template>

<script>
import Cookies from 'vue-cookies';
import Verify from './verifition/Verify';
import { sendMsg, smsLogin } from '@/utils/api';
export default {
	props: {
		dialogVisible: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Verify
	},
	data() {
		return {
			wxLogin: false,
			phone: '',
			smsCode: '',
			resendBtnText: '发送验证码',
			codeExpired: false,
			disableCodeSend: true,
			timer: null,
			count: 0,
			timeCount: 60,
			disabledLogin: true
		};
	},
	watch: {
		phone(newValue) {
			this.checkLoginDisable(newValue, this.smsCode);
			if (newValue === '') {
				this.disableCodeSend = true;
			} else {
				this.disableCodeSend = false;
			}
		},
		smsCode(newValue) {
			this.checkLoginDisable(this.phone, newValue);
		}
	},
	methods: {
		checkLoginDisable(phone, smsCode) {
			if (phone !== '' && smsCode != '') {
				this.disabledLogin = false;
			} else {
				this.disabledLogin = true;
			}
		},
		sendSmsLogin() {
			if (this.phone === '') {
				this.$message.error('请输入手机号码');
				return;
			}
			if (this.smsCode === '') {
				this.$message.error('请输入短信验证码');
				return;
			}
			var data = {
				phone: this.phone,
				smsCode: this.smsCode
			};
			smsLogin(data).then((res) => {
				console.log('smsLogin=====' + JSON.stringify(res));
				Cookies.set('token', res.data.accessToken, '7D');
				window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/#/Dashboard?token=' + Cookies.get('token');
			});
		},
		countDown() {
			this.disableCodeSend = true;
			this.count = this.timeCount;
			this.timer = setInterval(() => {
				if (this.count > 0 && this.count <= this.timeCount) {
					this.count--;
					this.resendBtnText = '重新获取(' + this.count + ')';
				} else {
					clearInterval(this.timer);
					this.timer = null;
					this.disableCodeSend = false;
					this.resendBtnText = '重新获取验证码';
				}
			}, 1000);
		},
		success(params) {
			// params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
			console.log(JSON.stringify(params));
			this.sendSmsCode(params.captchaVerification);
		},
		useVerify() {
			if (this.phone === '') {
				this.$message.error('请输入手机号码');
				return;
			}
			this.$refs.verify.show();
		},
		sendSmsCode(captchaVerification) {
			if (this.disableCodeSend) {
				this.$message({
					message: '已发送，请留意查看手机短信…',
					type: 'success'
				});
				return;
			}
			var data = {
				phone: this.phone,
				captchaCode: captchaVerification
			};
			sendMsg(data).then((res) => {
				console.log('sendMsg=====' + JSON.stringify(res));
				if (res.code !== 200) {
					return;
				}
				this.countDown();
			});
		},
		toWxLogin() {
			this.wxLogin = true;
		},
		toSmsLogin() {
			this.wxLogin = false;
		},
		handleClose() {
			this.$emit('update:dialogVisible', false);
		}
	}
};
</script>

<style lang="scss">
.login-dialog {
	.login-title {
		font-size: 25px;
		margin-top: -30px;
	}

	.qrcode-wx {
		border: #ccc solid 1px;
		border-radius: 2%;
		width: 200px;
		height: 200px;
		line-height: 200px;
		background: rgba(255, 255, 255, 0.95);

		margin: 0 auto; /* 设置左右外边距为auto */
		margin-top: 5px;
		img {
			width: 100%;
			height: 100%;
		}
	}

	.login-sms {
		padding: 25px;
		.sms-code {
			display: flex; /* 开启flex布局 */
			margin-top: 15px;
			input {
				margin-left: -8px;
				width: 95%;
			}
		}
		.login-btn {
			width: 100%;
			margin-top: 35px;
		}
	}

	.separator-with-text {
		position: relative;
		text-align: center;
		margin: 30px 30px;
	}

	.separator-with-text:before {
		content: '其他方式登录';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: white; /* 背景颜色与页面背景相同，以便文字清晰显示 */
		padding: 0 10px; /* 给文字添加一些内边距 */
		font-size: 14px; /* 可根据需要调整字体大小 */
		color: #9aa5b8;
	}

	.separator-with-text:after {
		content: '';
		display: block;
		border-top: 1px solid #e9edf2; /* 灰色分隔线 */
		width: 100%;
	}

	.otherLogin {
		margin-left: 37%;
		display: flex;
		align-items: center; /* 垂直居中 */
		img {
			width: 20px;
			height: 20px;
		}
	}
}
</style>
