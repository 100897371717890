<template>
	<div>
		<header class="header">
			<div class="container">
				<div class="logo-nav">
					<img @click="goHome()" style="cursor: pointer" src="@/assets/logo-big.png" alt="logo" class="logo" />
					<nav class="nav">
						<ul>
							<li><a href="/">首页</a></li>
							<li><a href="/about">关于我们</a></li>
							<li><a href="/study">知识社区</a></li>
						</ul>
					</nav>
					<div class="login">
						<el-button v-if="!loginFlag" type="primary" @click="openLoginDialog()">登录</el-button>
						<el-button v-else type="primary" @click="toDashboard()">进入控制台</el-button>
					</div>
				</div>
			</div>
		</header>
		<LoginDialog :dialogVisible.sync="dialogOpen" />
	</div>
</template>

<script>
import LoginDialog from '@/components/LoginDialog';
import Cookies from 'js-cookie';
import { loginUserInfo } from '@/utils/api';
export default {
	components: {
		LoginDialog
	},
	mounted() {
		this.loadUserInfo();
	},
	data() {
		return {
			dialogOpen: false,
			loginFlag: false
		};
	},
	methods: {
		goHome() {
			window.open('/', '_self', '', true);
		},
		toDashboard() {
			window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/#/Dashboard?token=' + Cookies.get('token');
		},
		loadUserInfo() {
			console.log('Cookies=============' + Cookies);
			var token = Cookies.get('token');
			if (!token || !token.trim()) {
				return;
			}
			loginUserInfo().then((res) => {
				this.loginFlag = res.data !== null;
			});
		},
		openLoginDialog() {
			this.dialogOpen = true;
		}
	}
};
</script>

<style lang="scss">
.logo {
	height: 50px;
}

.header {
	background-color: #fff;
	padding: 20px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #eaeaea;

	width: 100%; /* 头部宽度占满整个屏幕 */
	position: fixed; /* 固定定位 */
	top: 0; /* 头部距离页面顶部的距离为0 */
	left: 0; /* 头部距离页面左边的距离为0 */
	z-index: 1000; /* 确保头部在其他内容之上 */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影效果，可选 */

	.logo-nav {
		display: flex;
		align-items: center;

		.nav {
			width: 600px;
		}

		.nav ul {
			list-style: none;
			padding: 0;
			display: flex;
			margin: 0;
			width: 500px;
		}

		.nav li {
			font-size: 20px;
			font-weight: bold;
			margin-left: 50px;
		}

		.nav a {
			text-decoration: none;
			color: #333;
			transition: color 0.3s ease;
		}

		.nav a:hover {
			color: #007bff;
			cursor: pointer;
		}
	}

	.login {
		top: 20px;
		right: 15px;
		font-size: 14px;
		padding-left: 350px;
	}

	.login a {
		color: #333;
		text-decoration: none;
		padding: 5px 10px;
		border: 1px solid #ccc;
		border-radius: 3px;
		transition: background-color 0.3s ease, color 0.3s ease;
	}
}
</style>
