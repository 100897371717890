import request from '@/utils/axios'

// 发送短信
export function sendMsg(data) {
	return request({
		url: '/sms/ua/sendMsg',
		method: 'post',
		data
	})
}

// 验证码登录
export function smsLogin(data) {
	return request({
		url: '/login/ua/smsLogin',
		method: 'post',
		data
	})
}

// 退出登录
export function loginOut() {
	return request({
		url: '/login/ua/loginOut',
		method: 'post'
	})
}

// 获得登录用户信息
export function loginUserInfo(data) {
	return request({
		url: '/userInfo/ua/loginUserInfo',
		method: 'post',
		data
	})
}

// 获得最近绘画记录
export function getLastRecord(data) {
	return request({
		url: '/drawRecord/getLastRecord',
		method: 'post',
		data
	})
}

// 获得最近绘画记录
export function deleteRecord(data) {
	return request({
		url: '/drawRecord/deleteRecord/' + data,
		method: 'delete'
	})
}

// 获取画笔颜色字典
export function getStrokeBaseColor(data) {
	return request({
		url: '/dict/getStrokeBaseColor',
		method: 'GET'
	})
}

// 上传base64图片
export function saveBase64Image(data) {
	return request({
		url: '/file/ua/saveBase64Image',
		method: 'POST',
		data
	})
}

// 图生图
export function img2img(data) {
	return request({
		url: '/draw/img2img',
		method: 'POST',
		data
	})
}

// 文生图
export function txt2img(data) {
	return request({
		url: '/draw/txt2img',
		method: 'POST',
		data
	})
}

// 去背景(SD付加功能)
export function extraSingleImage(data) {
	return request({
		url: '/draw/extraSingleImage',
		method: 'POST',
		data
	})
}

// 去背景(rmbg2.0)
export function rmbg(data) {
	return request({
		url: '/draw/rmbg',
		method: 'POST',
		data
	})
}

// 背景图列表
export function listBackgroundImg(data) {
	return request({
		url: '/backgroundImg/listBackgroundImg',
		method: 'POST',
		data
	})
}

// 蒙板绘图
export function drawWithMask(data) {
	return request({
		url: '/draw/drawWithMask',
		method: 'POST',
		data
	})
}

// 蒙板绘图
export function generateMattingImage(data) {
	return request({
		url: '/draw/generateMattingImage',
		method: 'POST',
		data
	})
}

// 删除商品成品图
export function deleteGoodsById(id) {
	return request({
		url: '/goodsRecord/delete/'+id,
		method: 'DELETE'
	})
}

// 获取商品成品图
export function getGoodsById(id) {
	return request({
		url: '/goodsRecord/getById/'+id,
		method: 'GET'
	})
}

//高清修复
export function hdRepair(data) {
	return request({
		url: '/draw/hdRepair',
		method: 'POST',
		data
	})
}